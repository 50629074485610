html {
  scroll-behavior: smooth!important;
}

.t-dark {
  color: $primary-darker !important;

}
.t-light {
  color: $primary-lighter !important;
}

.text-orange {
  color: $orange !important;

}

.text-default {
color:#1e2022;

}

.text-primary {
  color:$primary !important;
}


.alignright {
  float:right;
  margin:20px;
  margin-right:0px;
}

/*------------------------------------
  Margin Spacing
------------------------------------*/

.ml-n2 {
  margin-left: -.5rem;
}

.ml-n3 {
  margin-left: -1rem;
}

.mt-n1 {
  margin-top: -.25rem;
}

.mt-n5 {
  margin-top: -2rem;
}

.mt-n6 {
  margin-top: -2.5rem;
}

.mt-n9 {
  margin-top: -4rem;
}

.mt-n23 {
  margin-top: -10rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n9 {
  margin-bottom: -4rem;
}

/* Gutters X */
.mx-gutters-2 {
  margin-right: -.5rem;
  margin-left: -.5rem;

  > .col,
  > [class*="col-"] {
    padding-right: .5rem;
    padding-left: .5rem;
  }
}
